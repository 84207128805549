<template>
<picture v-if="!!props.src">
  <source :srcset="srcset" :media="props.media"/>
  <slot/>
  <img :src="props.src" :alt="props.alt || ''" loading="lazy"/>
</picture>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  src: { type: String, required: true },
  alt: String,
  media: String,
})
const srcset = computed(() => {
  return props.src.replace(/(.png|.jpg|.jpeg|.webp|.gif)$/, '@2x$1');
});
</script>

<style lang="scss" scoped>
picture {
  display: block;
  img {
    display: block;
    max-width: 100%;
  }
}
</style>
